<template>
  <div class="identify">
    <div class="container" v-if="showStatus != 3">
      <Practice v-if="isPractice"></Practice>
      <Focus v-if="showStatus == 1"></Focus>
      <div v-if="showStatus == 2" style="margin-top: 75px">
        <div class="faceBox">
          <img v-show="showFace" :src="faceList[current].src" alt="" />
        </div>
        <ul class="options">
          <li
            :class="hoverIndex == index ? 'active optionItem' : 'optionItem'"
            v-for="(item, index) in options"
            :key="index"
            @mouseover="mouseover(index)"
            @mouseout="mouseout"
            @click="choose(item)"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>
    </div>
    <BlackScreen v-if="showStatus == 3"></BlackScreen>
  </div>
</template>

<script>
import Practice from '@/components/practice.vue';
import Focus from '@/components/focus.vue';
import BlackScreen from '@/components/blackScreen.vue';
import { saveSubjectiveAns } from '@/api/index';
//数据
let practiceData = [
  {
    id: 'EX1_recog_happy',
    name: '快乐',
    correct_answer: 'happy',
    src: require('@/assets/image/face/recog_excercise/EX1_recog_happy.jpg'),
  },
  {
    id: 'EX2_recog_sad',
    name: '伤心',
    correct_answer: 'sad',
    src: require('@/assets/image/face/recog_excercise/EX2_recog_sad.jpg'),
  },
  {
    id: 'EX3_recog_neutral',
    name: '平静',
    correct_answer: 'neutral',
    src: require('@/assets/image/face/recog_excercise/EX3_recog_neutral.jpg'),
  },
];
let formalData = [
  {
    id: 'A1F6',
    name: '生气',
    correct_answer: 'anger',
    src: require('@/assets/image/face/recognition/A1F6.jpg'),
  },
  {
    id: 'A1M7',
    name: '生气',
    correct_answer: 'anger',
    src: require('@/assets/image/face/recognition/A1M7.jpg'),
  },
  {
    id: 'A2F10',
    name: '生气',
    correct_answer: 'anger',
    src: require('@/assets/image/face/recognition/A2F10.jpg'),
  },
  {
    id: 'A2M6',
    name: '生气',
    correct_answer: 'anger',
    src: require('@/assets/image/face/recognition/A2M6.jpg'),
  },
  {
    id: 'A3F3',
    name: '生气',
    correct_answer: 'anger',
    src: require('@/assets/image/face/recognition/A3F3.jpg'),
  },
  {
    id: 'A3M11',
    name: '生气',
    correct_answer: 'anger',
    src: require('@/assets/image/face/recognition/A3M11.jpg'),
  },
  {
    id: 'D1F7',
    name: '厌恶',
    correct_answer: 'disgust',
    src: require('@/assets/image/face/recognition/D1F7.jpg'),
  },
  {
    id: 'D1M4',
    name: '厌恶',
    correct_answer: 'disgust',
    src: require('@/assets/image/face/recognition/D1M4.jpg'),
  },
  {
    id: 'D2F7',
    name: '厌恶',
    correct_answer: 'disgust',
    src: require('@/assets/image/face/recognition/D2F7.jpg'),
  },
  {
    id: 'D2M8',
    name: '厌恶',
    correct_answer: 'disgust',
    src: require('@/assets/image/face/recognition/D2M8.jpg'),
  },
  {
    id: 'D3F3',
    name: '厌恶',
    correct_answer: 'disgust',
    src: require('@/assets/image/face/recognition/D3F3.jpg'),
  },
  {
    id: 'D3M10',
    name: '厌恶',
    correct_answer: 'disgust',
    src: require('@/assets/image/face/recognition/D3M10.jpg'),
  },
  {
    id: 'F1F10',
    name: '害怕',
    correct_answer: 'fear',
    src: require('@/assets/image/face/recognition/F1F10.jpg'),
  },
  {
    id: 'F1M1',
    name: '害怕',
    correct_answer: 'fear',
    src: require('@/assets/image/face/recognition/F1M1.jpg'),
  },
  {
    id: 'F2F3',
    name: '害怕',
    correct_answer: 'fear',
    src: require('@/assets/image/face/recognition/F2F3.jpg'),
  },
  {
    id: 'F2M7',
    name: '害怕',
    correct_answer: 'fear',
    src: require('@/assets/image/face/recognition/F2M7.jpg'),
  },
  {
    id: 'F3F2',
    name: '害怕',
    correct_answer: 'fear',
    src: require('@/assets/image/face/recognition/F3F2.jpg'),
  },
  {
    id: 'F3M3',
    name: '害怕',
    correct_answer: 'fear',
    src: require('@/assets/image/face/recognition/F3M3.jpg'),
  },
  {
    id: 'H1F2',
    name: '快乐',
    correct_answer: 'happy',
    src: require('@/assets/image/face/recognition/H1F2.jpg'),
  },
  {
    id: 'H1M5',
    name: '快乐',
    correct_answer: 'happy',
    src: require('@/assets/image/face/recognition/H1M5.jpg'),
  },
  {
    id: 'H2F1',
    name: '快乐',
    correct_answer: 'happy',
    src: require('@/assets/image/face/recognition/H2F1.jpg'),
  },
  {
    id: 'H2M1',
    name: '快乐',
    correct_answer: 'happy',
    src: require('@/assets/image/face/recognition/H2M1.jpg'),
  },
  {
    id: 'H3F8',
    name: '快乐',
    correct_answer: 'happy',
    src: require('@/assets/image/face/recognition/H3F8.jpg'),
  },
  {
    id: 'H3M9',
    name: '快乐',
    correct_answer: 'happy',
    src: require('@/assets/image/face/recognition/H3M9.jpg'),
  },
  {
    id: 'N1F10',
    name: '平静',
    correct_answer: 'neutral',
    src: require('@/assets/image/face/recognition/N1F10.jpg'),
  },
  {
    id: 'N1M5',
    name: '平静',
    correct_answer: 'neutral',
    src: require('@/assets/image/face/recognition/N1M5.jpg'),
  },
  {
    id: 'N2F3',
    name: '平静',
    correct_answer: 'neutral',
    src: require('@/assets/image/face/recognition/N2F3.jpg'),
  },
  {
    id: 'N2M10',
    name: '平静',
    correct_answer: 'neutral',
    src: require('@/assets/image/face/recognition/N2M10.jpg'),
  },
  {
    id: 'N3F8',
    name: '平静',
    correct_answer: 'neutral',
    src: require('@/assets/image/face/recognition/N3F8.jpg'),
  },
  {
    id: 'N3M8',
    name: '平静',
    correct_answer: 'neutral',
    src: require('@/assets/image/face/recognition/N3M8.jpg'),
  },
  {
    id: 'S1F9',
    name: '伤心',
    correct_answer: 'sad',
    src: require('@/assets/image/face/recognition/S1F9.jpg'),
  },
  {
    id: 'S1M6',
    name: '伤心',
    correct_answer: 'sad',
    src: require('@/assets/image/face/recognition/S1M6.jpg'),
  },
  {
    id: 'S2F5',
    name: '伤心',
    correct_answer: 'sad',
    src: require('@/assets/image/face/recognition/S2F5.jpg'),
  },
  {
    id: 'S2M3',
    name: '伤心',
    correct_answer: 'sad',
    src: require('@/assets/image/face/recognition/S2M3.jpg'),
  },
  {
    id: 'S3F6',
    name: '伤心',
    correct_answer: 'sad',
    src: require('@/assets/image/face/recognition/S3F6.jpg'),
  },
  {
    id: 'S3M9',
    name: '伤心',
    correct_answer: 'sad',
    src: require('@/assets/image/face/recognition/S3M9.jpg'),
  },
  {
    id: 'SU1F10',
    name: '惊喜',
    correct_answer: 'surprise',
    src: require('@/assets/image/face/recognition/SU1F10.jpg'),
  },
  {
    id: 'SU1M10',
    name: '惊喜',
    correct_answer: 'surprise',
    src: require('@/assets/image/face/recognition/SU1M10.jpg'),
  },
  {
    id: 'SU2F8',
    name: '惊喜',
    correct_answer: 'surprise',
    src: require('@/assets/image/face/recognition/SU2F8.jpg'),
  },
  {
    id: 'SU2M5',
    name: '惊喜',
    correct_answer: 'surprise',
    src: require('@/assets/image/face/recognition/SU2M5.jpg'),
  },
  {
    id: 'SU3F6',
    name: '惊喜',
    correct_answer: 'surprise',
    src: require('@/assets/image/face/recognition/SU3F6.jpg'),
  },
  {
    id: 'SU3M5',
    name: '惊喜',
    correct_answer: 'surprise',
    src: require('@/assets/image/face/recognition/SU3M5.jpg'),
  },
];
export default {
  data() {
    return {
      isPractice: true, //是否练习题
      showStatus: 1, //1、光标+集中 2、答题 3、黑屏 4、完成
      hoverIndex: null,
      faceList: [], //数据
      current: 0, //当前进度
      options: [
        { name: '快乐', correct: 'happy' },
        { name: '惊喜', correct: 'surprise' },
        { name: '生气', correct: 'anger' },
        { name: '平静', correct: 'neutral' },
        { name: '伤心', correct: 'sad' },
        { name: '害怕', correct: 'fear' },
        { name: '厌恶', correct: 'disgust' },
      ],
      original: [], //原始数据
      startTime: 0, //起始时间
      endTime: 0, //结束时间
      startTimeTotal: 0, //起始总时间
      endTimeTotal: 0, //结束总时间
      totalTime: 0, //用时
      focusTimer: null,
      timer: null,
      timerSubmit1: null,
      timerSubmit2: null,
      showFace: true,
     
    };
  },
  components: {
    Practice,
    Focus,
    BlackScreen,
  },
  watch: {
    current() {
      this.progress();
    },
  },
  methods: {
    //出现随机排序及不连续处理
    faceSort() {
      this.$util.shuffle(this.faceList);
      let repeatArr = [];
      this.faceList.forEach((item, index) => {
        if (
          this.faceList[index + 2] &&
          this.faceList[index + 1].correct_answer ==
            this.faceList[index + 2].correct_answer &&
          this.faceList[index + 1].correct_answer ==
            this.faceList[index].correct_answer
        ) {
          item.repeat = true;
          repeatArr.push(item);
        }
      });
      if (repeatArr.length > 0) {
        let noRepeatArr = this.faceList.filter((item) => !item.repeat);
        this.faceList = noRepeatArr.reverse().concat(repeatArr);
      }
      this.faceList.forEach((item) => {
        //原始数据存储
        this.original.push({
          id: item.id,
          correct_answer: item.correct_answer,
        });
      });
      this.progress();
    },
    //选项随机倒序
    optionSort() {
      if (Math.random() - 0.5 > 0) {
        this.options = this.options.reverse();
        sessionStorage.setItem('reverseOrder','true');
      } else {
        sessionStorage.setItem('reverseOrder','false');
      }
    },
    mouseover(index) {
      this.hoverIndex = index;
    },
    mouseout() {
      this.hoverIndex = null;
    },
    //选择事件
    choose(item) {
      this.endTime = this.$util.getTimestamp();
      if (this.original[this.current].answer) {
        //已作答
        return false;
      } else {
        this.showFace = false;
        this.original[this.current].answer = item.correct;
        this.original[this.current].time = this.endTime - this.startTime;
        if (item.correct == this.original[this.current].correct_answer) {
          this.original[this.current].correct_type = 1;
        } else {
          this.original[this.current].correct_type = 0;
        }
        clearTimeout(this.timer);
        this.progressNext();
      }
    },
    //进行中
    progress() {
      this.focusTimer = setTimeout(() => {
        this.showStatus = 2;
        this.showFace = true;
        this.startTime = this.$util.getTimestamp();
      }, 800);
      this.timer = setTimeout(() => {
        this.showFace = false;
      }, 1800);
      // this.timer = setTimeout(() => {
      //   if (!this.original[this.current].answer) {
      //     //用户未操作
      //     this.original[this.current].answer = null;
      //     this.original[this.current].time = null;
      //     this.original[this.current].correct_type = 0;
      //     this.progressNext();
      //   }
      // }, 6500);
    },
    //进行中
    progressNext() {
      this.hoverIndex = null;
      this.showStatus = 3;
      // setTimeout(() => {
      if (this.current >= this.faceList.length - 1) {
        //完成
        this.finish();
      } else {
        this.current++;
        this.showStatus = 1;
      }
      // }, 1500);
    },
    //结束
    finish() {
      this.showStatus = 3;
      this.endTimeTotal = this.$util.getTimestamp();
      this.totalTime = this.endTimeTotal - this.startTimeTotal;
      if (this.isPractice) {
        //练习题
        // if (this.totalTime > 18000 - 1000) {
          this.$router.replace({
            path: '/practiceEnd',
            query: {
              source: 'faceIdentify',
            },
          });
        // } else {
        //   this.$router.replace({
        //     path: '/relax',
        //     query: {
        //       time: 18000 - this.totalTime,
        //       path: '/practiceEnd?source=faceIdentify',
        //     },
        //   });
        // }
      } else {
        //提交数据
        let query = {
          fmUserId: this.$util.getStorage('fmUserId'),
          fmProductQuestionId: '2',
          quesNum: '2-1',
          totalTime: this.totalTime > 240000 ? 240000 : this.totalTime,
          dataJason: JSON.stringify(this.original),
          remark:
            'id:图片编码；correct_answer：正确答案；answer：用户答案；time：用时ms；correct_type:是否正确，1正确，0错误；',
        };
        saveSubjectiveAns(query)
          .then((res) => {
            if (res) {
              //大题编号
              sessionStorage.setItem(
                'fmProductQuestionId',
                res.fmProductQuestionId
              );
              //小题编号
              sessionStorage.setItem('quesNum', res.quesNum);
              //是否完成
              sessionStorage.setItem('isFinished', res.isFinished);
              // if (this.totalTime > 240000 - 1000) {
                this.$router.replace({
                  path: '/complete',
                });
              // } else {
              //   this.$router.replace({
              //     path: '/relax',
              //     query: {
              //       time: 240000 - this.totalTime,
              //       path: '/complete',
              //     },
              //   });
              // }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  beforeDestroy() {
    clearTimeout(this.focusTimer);
    clearTimeout(this.timer);
    clearTimeout(this.timerSubmit1);
    clearTimeout(this.timerSubmit2);
  },
  created() {
    if (this.$route.query.practice) {
      this.isPractice = false;
    }
    if (this.isPractice) {
      this.faceList = practiceData;
      this.optionSort();
      this.timerSubmit1 = setTimeout(() => {
        this.finish();
      }, 18000);
    } else {
      if(sessionStorage.getItem('reverseOrder') == 'true') {
        this.options = this.options.reverse();
      } 
      this.faceList = formalData;
      this.timerSubmit2 = setTimeout(() => {
        this.finish();
      }, 240000);
    }
    this.faceSort();
  },
  mounted() {
    this.startTimeTotal = this.$util.getTimestamp();
  },
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 1900px) {
  .identify {
    font-family: 'SYHeiBold';
    display: table-cell;
    vertical-align: middle;
    .container {
      text-align: center;
      .faceBox {
        width: 260px;
        height: 300px;
        display: inline-block;
        border-radius: 8px;
        margin-bottom: 98px;
        img {
          width: 100%;
          border-radius: 8px;
        }
      }
      .options {
        width: 630px;
        height: 99px;
        // border: 8px solid #fce5b4;
        // background: #f7f7f7;
        // box-shadow: 0px 4px 16px rgba(136, 75, 0, 0.2);
        border-radius: 16px;
        margin: 0 auto;
        text-align: center;
        li {
          display: inline-block;
          width: 82px;
          height: 82px;
          line-height: 82px;
          text-align: center;
          background-image: url('../../assets/image/face/keyOption.png');
          background-repeat: no-repeat;
          background-size: cover;
          font-size: 20px;
          color: #5f9160;
          // box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.08);
          border-radius: 12px;
          cursor: url('http://upload.jahr.janjk.com/hand.ico'), pointer;
        }
        .active {
          color: #ffffff;
          background-image: url('../../assets/image/other/key_hover1.png');
          transform: scale(1.15);
        }
      }
    }
  }
}
@media screen and (min-width: 1900px) {
  .identify {
    font-family: 'SYHeiBold';
    display: table-cell;
    vertical-align: middle;
    .container {
      text-align: center;
      .faceBox {
        width: 338px;
        height: 390px;
        display: inline-block;
        border-radius: 10px;
        margin-bottom: 127px;
        img {
          width: 100%;
          border-radius: 10px;
        }
      }
      .options {
        width: 819px;
        height: 129px;
        // border: 8px solid #fce5b4;
        // background: #f7f7f7;
        // box-shadow: 0px 4px 16px rgba(136, 75, 0, 0.2);
        border-radius: 20px;
        margin: 0 auto;
        text-align: center;
        li {
          display: inline-block;
          width: 107px;
          height: 107px;
          line-height: 107px;
          text-align: center;
          background-image: url('../../assets/image/face/keyOption.png');
          background-repeat: no-repeat;
          background-size: cover;
          font-size: 26px;
          color: #5f9160;
          // box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.08);
          border-radius: 15px;
          cursor: url('http://upload.jahr.janjk.com/hand.ico'), pointer;
        }
        .active {
          color: #ffffff;
          background-image: url('../../assets/image/other/key_hover1.png');
          transform: scale(1.15);
        }
      }
    }
  }
}
</style>
